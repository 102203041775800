import 'moment/locale/eu';
import 'moment/locale/vi';
import { Routes, Route } from 'react-router-dom';
import React, { Suspense } from 'react';
import Loading from 'components/Loading';
import { language } from 'shared/language';
import PermissionDeniedPage from 'components/PermissionDenied';

const Print = React.lazy(() => import('components/Print'));

const NormalLogin = React.lazy(() => import('containers/Auth/Login/LoginView'));

const ForgotPassword = React.lazy(() => import('containers/Auth/ForgotPassword/ForgotPasswordView'));

const Announcement = React.lazy(() => import('containers/Announcement'));

const HelpDesk = React.lazy(() => import('containers/HelpDesk'));

const RentalUnit = React.lazy(() => import('containers/RentalUnit'));

const Member = React.lazy(() => import('containers/Member'));

const ServiceFee = React.lazy(() => import('containers/ServiceFee'));

const ApartmentMeeting = React.lazy(() => import('containers/ApartmentMeeting'));

const ApartmentMeetingCreate = React.lazy(() => import('containers/ApartmentMeeting/CreateMeeting/CreateMeetingView'));

const ApartmentMeetingUpdate = React.lazy(() => import('containers/ApartmentMeeting/UpdateMeeting/UpdateMeetingView'));

const ApartmentMeetingDetail = React.lazy(() => import('containers/ApartmentMeeting/DetailMeeting/DetailMeetingView'));

const ApplicationComponent = React.lazy(() => import('containers/App'));

// const Vehicle = React.lazy(() => import('containers/VehicleManagement/Vehicle'));

// const RegistrationForm = React.lazy(() => import('containers/VehicleManagement/RegistrationForm'));

const SaveFromTo = React.lazy(() => import('containers/Service/ReadingMeter'));

const SaveNumberUse = React.lazy(() => import('containers/Service/Fixed'));

const Parking = React.lazy(() => import('containers/Service/Parking'));

const Register = React.lazy(() => import('containers/Service/Register'));

const Visitor = React.lazy(() => import('containers/AccessManagement'));

function RootRoutes() {
  return (
    <Suspense fallback={<Loading loading />}>
      <Routes>
        <Route
          path="/print"
          element={<Print />}
        />
        <Route
          path="/login"
          element={
            <Suspense fallback={<Loading loading />}>
              <NormalLogin />
            </Suspense>
          }
        />
        <Route
          path="/forgot-password"
          element={
            <Suspense fallback={<Loading loading />}>
              <ForgotPassword />
            </Suspense>
          }
        />
        <Route
          path="/"
          element={
            <Suspense fallback={<Loading loading />}>
              <ApplicationComponent />
            </Suspense>
          }>
          <Route path="/application/:id/">
            <Route
              path="help-desk"
              element={
                <Suspense fallback={<Loading loading />}>
                  <HelpDesk />
                </Suspense>
              }
            />
            <Route
              path="financial"
              element={<div>{language.financial}</div>}
            />
            <Route
              path="chat"
              element={<div>{language.chat}</div>}
            />
            <Route
              path="announcement"
              element={
                <Suspense fallback={<Loading loading />}>
                  <Announcement />
                </Suspense>
              }
            />
            <Route
              path="member"
              element={
                <Suspense fallback={<Loading loading />}>
                  <Member />
                </Suspense>
              }
            />
            <Route
              path="information"
              element={
                <Suspense fallback={<Loading loading />}>
                  <RentalUnit />
                </Suspense>
              }
            />
            <Route
              path="service-fee"
              element={<ServiceFee />}
            />
            <Route
              path="apartment-meeting"
              element={<ApartmentMeeting />}
            />
            <Route
              path="apartment-meeting/create"
              element={<ApartmentMeetingCreate />}
            />
            <Route
              path="apartment-meeting/:idApartmentMeeting"
              element={<ApartmentMeetingDetail />}
            />
            <Route
              path="apartment-meeting/:idApartmentMeeting/update"
              element={<ApartmentMeetingUpdate />}
            />
            {/* <Route
              path="vehicle-list"
              element={
                <Suspense fallback={<Loading loading />}>
                  <Vehicle />
                </Suspense>
              }
            /> */}
            {/* <Route
              path="registration-form"
              element={
                <Suspense fallback={<Loading loading />}>
                  <RegistrationForm />/
                </Suspense>
              }
            /> */}

            <Route
              path="save-from-to"
              element={
                <Suspense fallback={<Loading loading />}>
                  <SaveFromTo />
                </Suspense>
              }
            />

            <Route
              path="save-number-use"
              element={
                <Suspense fallback={<Loading loading />}>
                  <SaveNumberUse />
                </Suspense>
              }
            />

            <Route
              path="parking"
              element={
                <Suspense fallback={<Loading loading />}>
                  <Parking />
                </Suspense>
              }
            />

            <Route
              path="register"
              element={
                <Suspense fallback={<Loading loading />}>
                  <Register />
                </Suspense>
              }
            />
            <Route
              path="access-management"
              element={
                <Suspense fallback={<Loading loading />}>
                  <Visitor />
                </Suspense>
              }
            />
          </Route>
        </Route>
        <Route
          path="*"
          element={
            <PermissionDeniedPage
              code="404"
              description={language.not_found_page}
            />
          }
        />
      </Routes>
    </Suspense>
  );
}

export default RootRoutes;
