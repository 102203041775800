export const NOT_NEED_PERMISSION = 'NOT_NEED_PERMISSION';

export const MODULE_APARTMENT = {
  READ: 'APARTMENT_READ',
  EXPORT: 'APARTMENT_EXPORT',
};

export const MODULE_APARTMENT_DETAIL = {
  READ: 'APARTMENT_DETAIL_READ',
  SERVICE_HISTORY_READ: 'APARTMENT_DETAIL_SERVICE_HISTORY_READ',
  TRANSACTION_HISTORY_READ: 'APARTMENT_DETAIL_TRANSACTION_HISTORY_READ',
};

export const permissions = {
  APARTMENT: Object.values(MODULE_APARTMENT),
  APARTMENT_DETAIL: Object.values(MODULE_APARTMENT_DETAIL),
  NOT_NEED_PERMISSION,
};

export const MODULE_SERVICE = {
  SERVICE_READ: 'SERVICE_READ',
  SERVICE_WRITE: 'SERVICE_WRITE',
  SERVICE_EXPORT: 'SERVICE_EXPORT',
};
