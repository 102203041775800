import { useState } from 'react';
import { getCountryCodesService } from 'services/base';

const useCountryCode = () => {
  const [listCountryCode, setListCountryCode] = useState([]);

  const getListCountryCode = async () => {
    const result = await getCountryCodesService();
    const countryCodes = result.data;
    setListCountryCode(countryCodes);
  };

  return { listCountryCode, getListCountryCode };
};

export default useCountryCode;
