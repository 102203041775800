import { useState } from 'react';
import { getServiceInUsesService, getServiceRegisterInUsesService } from 'services/service';

const useService = () => {
  const [serviceInUses, setServiceInUses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [serviceRegisterInUses, setServiceRegisterInUses] = useState([]);

  const getServiceInUses = async ({ idBuilding, filter }: any) => {
    setLoading(true);
    const response = await getServiceInUsesService({ idBuilding, filter });
    if (response.data) {
      setServiceInUses(response.data.sort((prevService: any, curService: any) => prevService.name.localeCompare(curService.name)));
    }
    setLoading(false);
    return response;
  };

  const getServiceRegisterInUses = async ({ idBuilding, filter }: any) => {
    setLoading(true);
    const response = await getServiceRegisterInUsesService({ idBuilding, filter });
    if (response.data) {
      setServiceRegisterInUses(response.data);
    }
    setLoading(false);
    return response;
  };

  return {
    getServiceRegisterInUses,
    getServiceInUses,
    serviceInUses,
    loading,
    serviceRegisterInUses,
  };
};

export default useService;
