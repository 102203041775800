const templateDataForImport: any = {
  // Visitor
  name: {
    vi: ['Hoàng', 'Sang', 'Quang'],
    en: ['Hoang', 'Sang', 'Quang'],
  },

  passPort: {
    vi: ['987763356', '987763357', '987763358'],
    en: ['987763356', '987763357', '987763358'],
  },

  phone: {
    vi: ['0982436587', '0982436588', '0982436589'],
    en: ['0982436587', '0982436588', '0982436589'],
  },

  email: {
    vi: ['angle@gmail.com', 'elite@gmail.com', 'john@gmail.com'],
    en: ['angle@gmail.com', 'elite@gmail.com', 'john@gmail.com'],
  },

  contactPersonName: {
    vi: ['Nguyễn Văn A', 'Nguyễn Văn B', 'Nguyễn Văn C'],
    en: ['A Van Nguyen', 'B Van Nguyen', 'C Van Nguyen'],
  },

  guestType: {
    vi: ['Khách thường', 'Khách quan trọng', 'Khách thường'],
    en: ['Guest normal', 'Guest Vip', 'Guest normal'],
  },

  expectedIncomingAt: {
    vi: ['06/12/2023 05:00', '07/12/2023 05:00', '08/12/2023 05:00'],
    en: ['06/12/2023 05:00', '07/12/2023 05:00', '08/12/2023 05:00'],
  },

  expectedLeavingAt: {
    vi: ['10/12/2023 05:00', '11/12/2023 05:00', '12/12/2023 05:00'],
    en: ['10/12/2023 05:00', '11/12/2023 05:00', '12/12/2023 05:00'],
  },

  description: {
    vi: ['Ghi chú 1', 'Ghi chú 2', 'Ghi chú 3'],
    en: ['Note1', 'Note2', 'Note3'],
  },
};

export default templateDataForImport;
