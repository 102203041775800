export interface IUnitGroupResponse {
  imageCover?: string;
  name?: string;
  id: number;
  usingDiscountOriginal?: boolean;
  unitGroupConfigs: any;
}

export interface IUnitGroupModel {
  imageCover?: string;
  name?: string;
  id: number;
  usingDiscountOriginal?: boolean;
  unitGroupConfigs: any;
}

const UnitGroupModel = (data?: IUnitGroupResponse): IUnitGroupModel => ({
  id: data?.id ?? 0,
  name: data?.name ?? '',
  imageCover: data?.imageCover ?? '',
  usingDiscountOriginal: data?.usingDiscountOriginal ?? false,
  unitGroupConfigs: data?.unitGroupConfigs?.length ? data?.unitGroupConfigs[0] : [],
});

export default UnitGroupModel;
