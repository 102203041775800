import { useState, useEffect } from 'react';
import { intersection } from 'lodash';
import { language } from '../language';
import { useTheme } from '@emotion/react';
import Button from 'components/Button';
import { checkNumber } from 'shared/utils';

type TButtonSelectAllRow = {
  isShow: boolean;
  isSelectAll: boolean;
  totalItemSelected: number;
  totalItem: number;
  // eslint-disable-next-line no-unused-vars
  onSelectAllItemTable: (input: boolean) => void;
  textSelectRow: string;
  textSelectAllRow: string;
};

const ButtonSelectAllRow = (props: TButtonSelectAllRow) => {
  const { isShow, isSelectAll, totalItemSelected, totalItem, onSelectAllItemTable, textSelectRow, textSelectAllRow } = props;

  const theme = useTheme();

  let component = (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}>
      <span
        style={{
          fontWeight: theme.fontWeight.bold,
          verticalAlign: 'middle',
        }}>
        {totalItem}
      </span>
    </div>
  );
  if (totalItemSelected > 0 || isSelectAll) {
    component = (
      <span>
        <span
          style={{
            color: theme.labelColor,
          }}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: !isSelectAll
              ? textSelectRow.replace('{totalItemSelected}', totalItemSelected.toString()).replace(
                  '{totalItem}',
                  `<span
                style='font-weight: ${theme.fontWeight.bold}; color: #000'
              >
                ${totalItem}
              </span>`,
                )
              : textSelectRow.replace('{totalItemSelected}', totalItem.toString()).replace(
                  '{totalItem}',
                  `<span
          style='font-weight: ${theme.fontWeight.bold}; color: #000'
        >
          ${totalItem}
        </span>`,
                ),
          }}
        />
        {totalItemSelected > 0 && (
          <Button
            style={{
              padding: '0 0 0 5px',
              height: 0,
              border: 0,
            }}
            type="link"
            onClick={() => onSelectAllItemTable(isShow && !isSelectAll)}>
            {totalItem === totalItemSelected
              ? ''
              : (isShow && !isSelectAll && textSelectAllRow.replace('{totalItem}', totalItem?.toString())) || language.deselect_all_rows}
          </Button>
        )}
      </span>
    );
  }
  return component;
};

type TUseTableCheckbox = {
  dataRows: Array<{
    [key: string]: any;
  }>;
  rowKey: string;
  totalRows: number;
};

type TObject = {
  [key: string]: any;
};

export default function useTableCheckbox(props: TUseTableCheckbox) {
  const { dataRows, rowKey, totalRows } = props;

  const [tableSelection, setDataTableSelection] = useState<{
    isSelectAllItem: boolean;
    isShowQuestionSelectAll: boolean;
    selectedRowsCache: any[];
    selectedRowKeysCache: (string | number)[];
    selectedRowKeys: (string | number)[];
    byId: {
      [key: string]: string;
    };
  }>({
    isSelectAllItem: false,
    isShowQuestionSelectAll: false,
    selectedRowsCache: [],
    selectedRowKeysCache: [],
    selectedRowKeys: [],
    byId: {},
  });

  const [leafRelation, setLeftRelation] = useState<{
    [key: string]: any[];
  }>({});
  const [nodeRelation, setNodeRelation] = useState<{
    [key: string]: any[];
  }>({});

  const buildStructureData = (
    data: Array<{
      [key: string]: any;
    }>,
    rowKeyBuild: string = 'key',
    byIdDefault: {
      [key: string]: any;
    } = {},
  ) => {
    const result: {
      [key: string]: any;
    } = {};
    const byId = byIdDefault;
    data.forEach((item) => {
      const stack = [];
      stack.push(item);
      byId[item[rowKeyBuild]] = item;
      let node;
      while (stack.length) {
        node = stack.pop();
        if (!node?.children) {
          result[node?.[rowKeyBuild]] = [...stack.map((i) => i[rowKeyBuild])];
        } else {
          const nextNode: { [key: string]: any } =
            node?.children?.find((child: { [key: string]: any }) => !result[child[rowKeyBuild]]) ?? undefined;

          if (nextNode) {
            stack.push(node);
            stack.push(nextNode);
            byId[node[rowKeyBuild]] = node;
            byId[nextNode[rowKeyBuild]] = nextNode;
          } else {
            stack.pop();
          }
        }
      }
    });

    const parent: {
      [key: string]: any;
    } = {};

    Object.keys(result)?.forEach((key) => {
      result[key]?.forEach((item: string) => {
        if (!parent[item]) {
          parent[item] = [];
        }
        parent[item]?.push(key);
      });
    });

    return [result, parent, byId];
  };

  const convertObjToArray = (obj: TObject) =>
    Object.keys(obj)
      .map((key: string) => (!checkNumber(+key) ? Number(key) : key))
      .filter(Boolean);

  const isLeaf = (record: { key: string }) => {
    return Boolean(leafRelation[record.key]);
  };

  useEffect(() => {
    const [leafRelationNew, nodeRelationNew, byId] = buildStructureData(dataRows, rowKey, tableSelection.byId);
    const selectedRowKeyObj: TObject = {};
    const selectedRowKeysCacheObj: TObject = {};
    [...tableSelection.selectedRowKeysCache].forEach((key) => {
      if (!selectedRowKeysCacheObj[key]) {
        selectedRowKeysCacheObj[key] = key;
      }
    });

    dataRows.forEach((dataRow) => {
      if (!tableSelection.isSelectAllItem && selectedRowKeysCacheObj[dataRow[rowKey]]) {
        selectedRowKeyObj[dataRow[rowKey]] = dataRow[rowKey];
        nodeRelationNew?.[dataRow?.[rowKey]]?.forEach((leafKey: string | number) => {
          selectedRowKeyObj[leafKey] = leafKey;
        });
      }
      if (tableSelection.isSelectAllItem) {
        selectedRowKeyObj[dataRow[rowKey]] = dataRow[rowKey];
        selectedRowKeysCacheObj[dataRow[rowKey]] = dataRow[rowKey];
        nodeRelationNew?.[dataRow?.[rowKey]]?.forEach((leafKey: string | number) => {
          selectedRowKeyObj[leafKey] = leafKey;
          selectedRowKeysCacheObj[leafKey] = leafKey;
        });
      }
    });

    const selectedRowsCache = Object.keys(selectedRowKeysCacheObj)
      .map((key) => tableSelection.byId[key])
      .filter(Boolean);

    setLeftRelation(leafRelationNew);
    setNodeRelation(nodeRelationNew);
    setDataTableSelection({
      ...tableSelection,
      byId,
      selectedRowsCache,
      selectedRowKeysCache: convertObjToArray({ ...selectedRowKeysCacheObj }),
      selectedRowKeys: convertObjToArray({ ...selectedRowKeyObj }),
    });
  }, [dataRows, rowKey, tableSelection.byId, tableSelection.isSelectAllItem]);

  const resetDataTableSelection = () => {
    setDataTableSelection({
      isSelectAllItem: false,
      isShowQuestionSelectAll: false,
      selectedRowsCache: [],
      selectedRowKeysCache: [],
      selectedRowKeys: [],
      byId: tableSelection.byId ?? {},
    });
  };

  const onSelectRow = (record: any, selected: boolean) => {
    const isRecordLeaf = isLeaf(record);
    // convert key obj
    const selectedRowKeyObj: TObject = {};
    const selectedRowKeysCacheObj: TObject = {};
    [...tableSelection.selectedRowKeys].forEach((key) => {
      if (!selectedRowKeyObj[key]) {
        selectedRowKeyObj[key] = key;
      }
    });
    [...tableSelection.selectedRowKeysCache].forEach((key) => {
      if (!selectedRowKeysCacheObj[key]) {
        selectedRowKeysCacheObj[key] = key;
      }
    });

    if (selected) {
      selectedRowKeyObj[record.key] = record.key;
      selectedRowKeysCacheObj[record.key] = record.key;
      if (isRecordLeaf) {
        leafRelation?.[record?.key]?.forEach((nodeKey) => {
          const leafData = nodeRelation[nodeKey];
          const intersectionData = intersection(convertObjToArray(selectedRowKeyObj), leafData);
          if (leafData.length && intersectionData.length && intersectionData.length === leafData.length) {
            selectedRowKeyObj[nodeKey] = nodeKey;
            selectedRowKeysCacheObj[nodeKey] = nodeKey;
          }
        });
      }

      if (!isRecordLeaf) {
        const leafData = nodeRelation[record.key];
        leafData.forEach((leafKey) => {
          selectedRowKeyObj[leafKey] = leafKey;
          selectedRowKeysCacheObj[leafKey] = leafKey;
        });
      }
    }

    if (!selected) {
      delete selectedRowKeyObj[record.key];
      delete selectedRowKeysCacheObj[record.key];
      if (isRecordLeaf) {
        leafRelation?.[record?.key]?.forEach((nodeKey) => {
          delete selectedRowKeyObj[nodeKey];
          delete selectedRowKeysCacheObj[nodeKey];
        });
      }

      if (!isRecordLeaf) {
        nodeRelation?.[record?.key]?.forEach((leafKey) => {
          delete selectedRowKeyObj[leafKey];
          delete selectedRowKeysCacheObj[leafKey];
        });
      }
    }

    const selectedRowsCache = Object.keys(selectedRowKeysCacheObj)
      .map((key) => tableSelection.byId[key])
      .filter(Boolean);

    setDataTableSelection({
      ...tableSelection,
      isSelectAllItem: false,
      isShowQuestionSelectAll: false,
      selectedRowsCache,
      selectedRowKeysCache: convertObjToArray({ ...selectedRowKeysCacheObj }),
      selectedRowKeys: convertObjToArray({ ...selectedRowKeyObj }),
    });
  };

  const onSelectAllRowOfPage = (
    selected: boolean,
    selectedRows: Array<{
      [key: string]: any;
    }>,
  ) => {
    const selectedRowKeys = selectedRows.filter(Boolean).map((selectedRow) => selectedRow[rowKey]);
    const selectedRowKeysCacheObj: TObject = {};
    [...tableSelection.selectedRowKeysCache].forEach((key) => {
      if (!selectedRowKeysCacheObj[key]) {
        selectedRowKeysCacheObj[key] = key;
      }
    });

    if (selected) {
      selectedRowKeys.forEach((key) => {
        selectedRowKeysCacheObj[key] = key;
      });
    }

    if (!selected) {
      tableSelection.selectedRowKeys.forEach((key) => {
        delete selectedRowKeysCacheObj[key];
      });
    }

    const selectedRowsCache = Object.keys(selectedRowKeysCacheObj)
      .map((key) => tableSelection.byId[key])
      .filter(Boolean);

    setDataTableSelection({
      ...tableSelection,
      isShowQuestionSelectAll: selected,
      isSelectAllItem: false,
      selectedRowsCache,
      selectedRowKeysCache: convertObjToArray({ ...selectedRowKeysCacheObj }),
      selectedRowKeys,
    });
  };

  const onSelectAllRow = (selected: boolean) => {
    if (!selected) {
      setDataTableSelection({
        ...tableSelection,
        isSelectAllItem: false,
        isShowQuestionSelectAll: false,
        selectedRowsCache: [],
        selectedRowKeysCache: [],
        selectedRowKeys: [],
      });
    } else {
      setDataTableSelection({
        ...tableSelection,
        isSelectAllItem: true,
      });
    }
  };

  const selectAllRowComponent = (
    propsSelectAll: {
      totalItemSelected: number;
      textSelectRow: string;
      textSelectAllRow: string;
    } = {
      totalItemSelected: tableSelection.selectedRowKeysCache.length,
      textSelectRow: language.you_selected_rows,
      textSelectAllRow: language.select_all_rows,
    },
  ) => {
    const { totalItemSelected, textSelectRow, textSelectAllRow } = propsSelectAll;

    return (
      <ButtonSelectAllRow
        isShow={tableSelection.isShowQuestionSelectAll}
        isSelectAll={tableSelection.isSelectAllItem}
        totalItemSelected={totalItemSelected}
        totalItem={totalRows}
        textSelectRow={textSelectRow}
        textSelectAllRow={textSelectAllRow}
        onSelectAllItemTable={onSelectAllRow}
      />
    );
  };

  return [tableSelection, resetDataTableSelection, onSelectRow, onSelectAllRowOfPage, selectAllRowComponent];
}
