import { API_TYPE_CALL_API, METHOD, SERVER, X_UNIT_GROUP_ID } from 'shared/constants';
import api from './api';

export const SERVICE = `${SERVER}/service`;
export const SERVICE_IN_USE_GETS = `${SERVICE}/list-in-use`;
export const SERVICE_REGISTER_IN_USE_GETS = `${SERVICE}/register/list-in-use`;

type TGetListService = {
  filter: any;
  idBuilding: string | undefined;
};

type TGetListServiceRegisterInUse = {
  filter: any;
  idBuilding: string | undefined;
};

export async function getServiceInUsesService({ idBuilding, filter }: TGetListService) {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: SERVICE_IN_USE_GETS,
      method: METHOD.GET,
      params: {
        ...filter,
      },
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
}

export async function getServiceRegisterInUsesService({ idBuilding, filter }: TGetListServiceRegisterInUse) {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: SERVICE_REGISTER_IN_USE_GETS,
      method: METHOD.GET,
      params: {
        ...filter,
      },
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
}
