export const SERVER = process.env.REACT_APP_API_URL;
export const SERVER_WEB_ADMIN = process.env.REACT_APP_API_WEB_ADMIN_URL;
export const FORM_DATA_SERVER = process.env.REACT_APP_FORM_DATA_API_URL;
export const PARSE_SERVER_URL = process.env.REACT_APP_PARSE_SERVER_URL || '';
export const PARSE_SERVER_APPLICATION_ID = process.env.REACT_APP_PARSE_SERVER_APPLICATION_ID || '';
export const PARSE_SERVER_JAVASCRIPT_KEY = process.env.REACT_APP_PARSE_SERVER_JAVASCRIPT_KEY || '';
export const PARSE_SERVER_REST_API_KEY = process.env.REACT_APP_PARSE_SERVER_REST_API_KEY || '';
export const CAPTCHA_KEY = process.env.REACT_APP_CAPTCHA_KEY || '';
export const COOKIE_TAG = process.env.REACT_APP_COOKIE_TAG || 'cyhome';
export const DEFAULT_COUNTRY_CALLING_CODE = process.env.REACT_APP_COUNTRY_CALLING_CODE || '+84';
export const PAGINATION_LIMIT = +(process.env.REACT_APP_PAGINATION_LIMIT || '20');
export const FCM_KEY_PAIR = process.env.REACT_APP_FCM_KEY_PAIR;
export const ENV = process.env.REACT_APP_ENV || 'development';
export const FCM_SENDER_ID = process.env.REACT_APP_FCM_SENDER_ID;
export const FCM_API_KEY = process.env.REACT_APP_FCM_API_KEY;
export const FCM_PROJECT_ID = process.env.REACT_APP_FCM_PROJECT_ID;
export const FCM_APP_ID = process.env.REACT_APP_FCM_APP_ID;
export const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID;
export const WHITE_LABEL = (process.env.REACT_APP_WHITE_LABEL || 'CYADMIN').toLowerCase();
export const USER_TYPE = process.env.REACT_APP_USER_TYPE || 'RESIDENT';
